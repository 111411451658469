import {Component, OnInit} from '@angular/core';
import {HupSubscriptionsService} from "../../../data/services/hup-subscriptions.service";
import {BaseDataService} from "../../../core/services/base-data.service";
import {BaseData} from "../../../data/models/base-data.model";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslationService} from "../../../core/services/translation.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SnackbarService} from "../../../core/services/snackbar.service";
import {NavigationCommand, NavigationService} from "../../../data/services/navigation.service";
import {ConfigService} from '../../../core/services/config.service';

@Component({
  selector: 'app-hup-subscription-terminate',
  templateUrl: './hup-subscription-terminate.component.html',
  styleUrls: ['./hup-subscription-terminate.component.scss']
})
export class HupSubscriptionTerminateComponent implements OnInit {

  terminationTypes: BaseData[];
  subId: number;
  terminationForm: FormGroup;
  minDate: Date = new Date();
  isLoading: boolean = false;
  showBackToOverviewButton: boolean;
  constructor(
    private hupSubscriptionService: HupSubscriptionsService,
    private baseData: BaseDataService,
    private route: ActivatedRoute,
    public translationService: TranslationService,
    private formBuilder: FormBuilder,
    private snackBar: SnackbarService,
    private navigationService: NavigationService,
    private configService: ConfigService
  ) {
    this.route.paramMap.subscribe((params) => {
      this.subId = +params.get('subId');

      this.terminationForm = this.formBuilder.group({
        subId: [this.subId, Validators.required],
        reason: ['', Validators.required],
        terminationDate: [this.minDate, Validators.required]
      });

      this.configService.loadConfig('show.backToOverView.button').subscribe(config => {
        if (config && config.value) {
          this.showBackToOverviewButton = (config.value === '1');
        }
      });
      this.terminationForm.get('reason').valueChanges.subscribe(value => {
        this.hupSubscriptionService.getTerminationDateOfSubscription(this.subId, value.key).subscribe(date => {
          const actualDate = this.terminationForm.get('terminationDate').value;
          const terminationDate = new Date(date);
          if ( actualDate < terminationDate ) {
            this.terminationForm.get('terminationDate').setValue(terminationDate);
            this.minDate = terminationDate;
          }
        })
      });
    });
  }

  ngOnInit(): void {
    this.terminationTypes = this.baseData.getBaseData('terminationtypes');
  }

  terminateSubscription() {
    const terminationType: BaseData = this.terminationForm.get('reason').value;
    const terminationDate: Date = this.terminationForm.get('terminationDate').value;
    this.isLoading = true;
    this.hupSubscriptionService.terminateSubscription(0, this.subId, terminationType.key, terminationDate).subscribe(
      (result) => {
        this.isLoading = false;
        // auf die Übersicht wandern..
        this.navigationService.navigateTo(NavigationCommand.SUBSCRIPTION).then((navigated) => {
          if (navigated) {
            this.snackBar.openSnackbar(this.translationService.getTranslation("saved"));
          }
        });
      }
    );
  }

  backToOverview(): void {
    this.navigationService.navigateTo(NavigationCommand.SUBSCRIPTION, null, true);
  }

  printForm(): void {
    console.log(this.terminationForm);
  }
}
