import {Inject, Injectable, PLATFORM_ID} from "@angular/core";
import {GtmEnum} from "../models/gtm.enum";
import {v4 as uuidv4} from 'uuid';
import {OrderTrackingEntry} from "../../data/models/order-tracking-entry.model";
import {HttpClient} from "@angular/common/http";
import {DatePipe, isPlatformBrowser} from "@angular/common";

@Injectable({providedIn: 'root'})
export class OrderTrackingService {

    transactionId : string;
    constructor(
        private http: HttpClient,
        private datePipe: DatePipe,
        @Inject(PLATFORM_ID) private platformId,
    ) {
    }

    pushEvent(event: GtmEnum, eventData: object): void {

      try {
        if (isPlatformBrowser(this.platformId) && dataLayer !== undefined && dataLayer !== null) {
          const filtertData: any = {...eventData};
          delete filtertData.deliveryAddress;
          delete filtertData.billingAddress;
          delete filtertData.bonusAddress;
          delete filtertData.payment;
          const pushData = {event, objectData: JSON.stringify(filtertData)};
          dataLayer.push(pushData);
        }
      } catch (e) {
        // Datalayer ist wohl nicht da..
      }

        if(event !== GtmEnum.SITE_NAVIGATION) {
          let eventDataList = [];
          if(!Array.isArray(eventData)) {
             eventDataList.push(eventData);
          } else {
            eventDataList = eventData;
          }
          if (isPlatformBrowser(this.platformId)) {
            try {
              let orderStep = new OrderTrackingEntry();

              orderStep.ts = this.datePipe.transform(Date(), 'yyyy-MM-dd HH:mm:ss');
              orderStep.transactionId = this.transactionId;
              orderStep.eventType = event.toString().toUpperCase();
              orderStep.eventData = eventDataList;

              this.http.post('webabo/tracking/orderevent', orderStep).subscribe(success => success);
            } catch (e) {
              console.error("Error while saving tracking: " + e);
            }
          }
        }
    }

    generateTransactionId(): void {
      this.transactionId = uuidv4();
    }
}
