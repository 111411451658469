import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TranslationService} from "../../../core/services/translation.service";
import {AddressListItem} from "../../../data/models/address-list.model";
import {RedirectService} from "../../../data/services/redirect.service";
import {HupSubscriptionsService} from "../../../data/services/hup-subscriptions.service";
import {SnackbarService} from "../../../core/services/snackbar.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-contact-without-login',
  templateUrl: './contact-without-login.component.html',
  styleUrls: ['./contact-without-login.component.css']
})
export class ContactWithoutLoginComponent implements OnInit {

  clientNoForm: FormGroup;
  addressForm: FormGroup;
  errorsArr: string[] = [];
  isLoading: boolean = false;
  isFirst: boolean = true;
  clientFound: boolean = false;
  customer: AddressListItem;
  emailExists: boolean = false;
  emailAddress: string;
  constructor(
    private formBuilder: FormBuilder,
    public translationService: TranslationService,
    private redirectService: RedirectService,
    private subscriptionService: HupSubscriptionsService,
    private snackBar: SnackbarService,
    private router: Router
  ) {
    this.clientNoForm = this.formBuilder.group({
      clientNo: ['', Validators.required]
    });

    this.addressForm = this.formBuilder.group({
      contactAddress: this.formBuilder.group({
        showAddress: [''],
        address: this.formBuilder.group({
          country: [''],
          zipcode: [''],
          city: [''],
          street: [''],
          houseNo: [''],
          extraLine: [''],
          houseNoExt: [''],
          stiege: [''],
          stock: [''],
          tuer: [''],
        }),
        person: this.formBuilder.group({
          title: [''],
          salutation: [''],
          firstName: [''],
          lastName: [''],
          initials: [''],
          middlename: [''],
        })
      })
    });
  }

  ngOnInit(): void {
  }

  loadCustomerByClientNo(clientno): void {
    if( clientno && clientno > 0) {
      this.isLoading = true;
      this.subscriptionService.getCustomerUnauthorized(clientno).subscribe(customer => {
        if (customer) {
          this.customer = customer;
          this.clientFound = true;
          if (customer.emailList && customer.emailList.length > 0) {
            this.emailExists = true;
          }
        } else {
          this.clientFound = false;
        }
        this.isFirst = false;
        this.isLoading = false;
      });
    } else {
      this.clientFound = false;
      this.isFirst = true;
    }
  }

  savedRequest(saved: boolean) {
    this.addressForm.reset();
    this.emailAddress = "";
    this.router.navigate(['/contact']).then((navigated) => {
      if (navigated) {
        this.snackBar.openSnackbar(this.translationService.getTranslation("contact_unauthorized_saved"));
      }
    });
  }

  protected readonly document = document;
}
